<template>
  <!-- 资讯列表详情页 -->
  <div class="details-news">
    <div class="details-news-item">
      <div class="item-box">
        <h2>{{uewsItem.title}}</h2>
        <div class="item-left">
          <!-- 来源信息 -->
          <i class="iconfont source icon-laiyuan"></i>
          <p>{{uewsItem.source}}</p>
          <div class="news-item">
            <i class="iconfont icon-riqi"></i>
            <span class="item">{{time[0]}}</span>
            <div class="ri">
              <span>{{time[1]}} / </span>
              <span>{{time[2]}}</span>
            </div>
          </div>
        </div>
        <div class="item-right">
          <!-- 标签 -->
          <div class="tag" v-if="tagList.length > 0">
            <el-tag type="info" v-for="(tag,i) in tagList" :key="i">{{tag}}</el-tag>
          </div>
          <!-- 详情内容 -->
          <div class="content_box" style="font-size:14px; line-height: 26px;" v-html="contentHtml">
          </div>
        </div>
      </div>

      <!-- 点赞/收藏盒子 -->
      <!-- <div class="operate">
        <div class="dianzan">
          <i class="iconfont  hand dian icon-dianzan-copy"></i>
          <span>{{num}}人点赞</span>
        </div>

        <div class="shoucang">
          <i class="iconfont  hand dian icon-shoucang1"></i>
          <span>{{shouNum}}人收藏</span>
        </div>
      </div> -->

    </div>
  </div>
</template>
<script>
import { getNewsById } from '@/api/news.js'
export default {
  name: 'detailsNews',
  data () {
    return {
      uewsId: null, // 资讯id
      uewsItem: {}, // 资讯详情
      contentHtml: '', // 替换过的html
      time: [], // 日期
      tagList: [] // 标签数据
    }
  },
  methods: {
    // 获取资讯详情
    async getNewsById () {
      try {
        const res = await getNewsById({ newsId: this.uewsId })
        this.uewsItem = res.data
        this.contentHtml = this.maximg(this.uewsItem.contentHtml)
        this.time = this.$initTime(this.uewsItem.publishDate).split('-')
        if (this.uewsItem.label) {
          this.tagList = this.uewsItem.label && this.uewsItem.label.split('-')
        }
      } catch (error) {
        return this.$message.error('获取资讯详情失败')
      }
    },
    // 处理过大的图片样式
    maximg (src) {
      return src.replace(/<img/g, '<img style="max-width: 100%; display: block; margin: 0 auto;"').replace(/<p/g, '<p style="text-indent: 2em;"')
    }
  },
  computed: {
    // 点赞随机数
    num () {
      return Math.floor(Math.random() * (500 - 200)) + 200
    },
    // 收藏随机数
    shouNum () {
      return Math.floor(Math.random() * (600 - 250)) + 230
    }
  },
  created () {
    this.uewsId = this.$route.query.newsId
    this.getNewsById()
  }
}
</script>
<style lang="less" scoped>
.details-news {
  .details-news-item {
    min-height: calc(100vh - 273px);
    background-color: #f5f7fb;
    padding: 30px 0;
    .item-box {
      display: flex;
      flex-wrap: wrap;
      width: 1100px;
      margin: auto;
      padding: 20px 0;
      border-radius: 2px;
      background-color: #fff;
      h2 {
        width: 100%;
        height: 40px;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 20px;
      }
      .item-left {
        flex: 1;
        border-right: 1px solid #9faccc;
        text-align: center;
        display: none;
        .source {
          display: block;
          font-size: 50px;
          color: #4b639f;
        }
        p {
          margin: 15px 0 20px;
          font-size: 18px;
          color: #8b8b8b;
        }

        .news-item {
          position: relative;
          i {
            color: #4b639f;
            font-size: 120px;
          }
          .item {
            position: absolute;
            left: 61px;
            top: 62px;
            font-size: 16px;
          }
          .ri {
            position: absolute;
            left: 55px;
            top: 82px;
            font-size: 14px;
          }
        }
      }
      .item-right {
        flex: 6;
        width: 945px;
        padding: 20px;
        .el-tag {
          margin: 0 15px 20px 0;
          background-color: #f5f7fb;
        }
        .content_box {
          word-break: break-all;
        }
      }
    }
    // 底部 操作
    .operate {
      display: flex;
      justify-content: space-around;
      width: 300px;
      height: 100px;
      margin: 0 auto;
      i {
        display: block;
        width: 100%;
        font-size: 36px;
        margin-bottom: 10px;
        color: #cdcdcd;
        text-align: center;
      }
      span {
        font-size: 18px;
      }
      .dian {
        color: #ff9600;
      }
    }
  }
}
</style>
